import moment from 'moment'
import { FitnessArrivalConsultationField } from '../NoteFormsConstants'
import {ReactComponent as CheckedIcon} from '../../../../assets/images/checked.svg'
import {ReactComponent as UncheckedIcon} from '../../../../assets/images/unchecked.svg'
import React from 'react'
import targetIcon from '../../../../assets/images/target.svg'
import RadioButton from '../../../../components/RadioButton/RadioButton'


export const ViewFitnessArrivalConsultation = ({content, conductedBy})=>{
    return (
        <div className="my-3 ">
            <div className='border rounded my-4 overflow-hidden'>
                <div className="bg-light text-center text-brown text-uppercase border-bottom py-2 fw-semibold">
                    Cardio (Treadmill) test
                </div>
                <div className="row m-0 fw-semibold" >
                    <div className='p-2 border col-2'></div>
                    <div className="p-2 border col-2">Speed</div>
                    <div className='p-2 border col-2'> Incline</div>
                    <div className='p-2 border col-2'>Duration</div>
                    <div className='p-2 border col-2'>HR</div>
                    <div className='p-2 border col-2'>Comments</div>
                </div>
                    {
                        FitnessArrivalConsultationField?.cardioTest?.map((item, index)=>{
                            return (
                                <div className="row m-0" key={index}>
                                    <div className=" border border-bottom-0 p-2 col-2 fw-semi-bold text-secondary ">{item.label}</div>
                                    <div className='border border-bottom-0 p-2 col-2'>
                                        {content?.data?.cardio_test?.[item.key]?.speed ? `${content?.data?.cardio_test?.[item.key]?.speed} KMPH`: '-'}
                                    </div>
                                    <div className='border border-bottom-0 p-2 col-2'>
                                        {content?.data?.cardio_test?.[item.key]?.incline ? `${content?.data?.cardio_test?.[item.key]?.speed} %` : '-'}
                                    </div>
                                    <div className='border border-bottom-0 p-2 col-2'>
                                        {content?.data?.cardio_test?.[item.key]?.duration ? `${content?.data?.cardio_test?.[item.key]?.duration} Min` : '-'}
                                    </div>
                                    <div className='border border-bottom-0 p-2 col-2'>
                                        {content?.data?.cardio_test?.[item.key]?.hr || '-'}
                                    </div>
                                    <div className='border border-bottom-0 p-2 col-2'>
                                        {content?.data?.cardio_test?.[item.key]?.comments || '-'}
                                    </div>
                                </div>
                            )
                        })
                    }
            </div>

            <div className='border rounded my-4 overflow-hidden'>
                <div className="bg-light text-center text-brown text-uppercase border-bottom py-2 fw-semibold">
                    Strength
                </div>
                <div className="row m-0 fw-semibold" >
                    <div className='p-2 border col-3'></div>
                    <div className='p-2 border col-3'>Weight</div>
                    <div className='p-2 border col-3'>Reps</div>
                    <div className='p-2 border col-3'>Comments</div>
                </div>
                    {
                        FitnessArrivalConsultationField?.strength?.map((item, index)=>{
                            return (
                                <div className="row m-0" key={index}>
                                    <div className=" border border-bottom-0 p-2 col-3 fw-semi-bold text-secondary ">{item.label}</div>
                                    <div className='border border-bottom-0 p-2 col-3'>
                                        {content?.data?.strength?.[item.key]?.weight ? `${content?.data?.strength?.[item.key]?.weight} Kg` : '-'}
                                    </div>
                                    <div className='border border-bottom-0 p-2 col-3'>
                                        {content?.data?.strength?.[item.key]?.reps || '-'}
                                    </div>
                                    <div className='border border-bottom-0 p-2 col-3'>
                                        {content?.data?.strength?.[item.key]?.comments || '-'}
                                    </div>
                                </div>
                            )
                        })
                    }
            </div>

            <div className='border rounded my-4 overflow-hidden'>
                <div className="bg-light text-center text-brown text-uppercase border-bottom py-2 fw-semibold">
                    Muscular Endurance
                </div>
                <div className="row m-0 fw-semibold" >
                    <div className='p-2 border col-3'></div>
                    <div className='p-2 border col-4'>Unit</div>
                    <div className='p-2 border col-5'>Comments</div>
                </div>
                    {
                        FitnessArrivalConsultationField?.muscularEndurance?.map((item, index)=>{
                            return (
                                <div className="row m-0" key={index}>
                                    <div className=" border border-bottom-0 p-2 col-3 fw-semi-bold text-secondary ">{item.label}</div>
                                    <div className='border border-bottom-0 p-2 col-4'>
                                        {content?.data?.muscular_endurance?.[item.key]?.units || '-'}
                                    </div>
                                    <div className='border border-bottom-0 p-2 col-5'>
                                        {content?.data?.muscular_endurance?.[item.key]?.comments || '-'}
                                    </div>
                                </div>
                            )
                        })
                    }
            </div>

            <div className='border rounded my-4 overflow-hidden'>
                <div className="bg-light text-center text-brown text-uppercase border-bottom py-2 fw-semibold">
                    Flexibility
                </div>
                <div className="row m-0 fw-semibold" >
                    <div className='p-2 border col-3'></div>
                    <div className='p-2 border col-3'>Right Side</div>
                    <div className='p-2 border col-3'>Left Side</div>
                    <div className='p-2 border col-3'>Comments</div>
                </div>
                    {
                        FitnessArrivalConsultationField?.flexibility?.map((item, index)=>{
                            return (
                                <div className="row m-0" key={index}>
                                    <div className=" border border-bottom-0 p-2 col-3 fw-semi-bold text-secondary ">{item.label}</div>
                                    <div className='border border-bottom-0 p-2 col-3'>
                                        {content?.data?.flexibility?.[item.key]?.right_side || '-'}
                                    </div>
                                    <div className='border border-bottom-0 p-2 col-3'>
                                        {content?.data?.flexibility?.[item.key]?.left_side || '-'}
                                    </div>
                                    <div className='border border-bottom-0 p-2 col-3'>
                                        {content?.data?.flexibility?.[item.key]?.comments || '-'}
                                    </div>
                                </div>
                            )
                        })
                    }
            </div>
            
            <div className='border rounded my-4 overflow-hidden'>
                <div className="text-brown text-uppercase p-2 fw-semibold">
                Exercise Profile
                </div>
                <div className="p-2 ps-4 pt-0">
                    <RadioButton
                        label={"Sedentary"}
                        name={"excercise_profile"}
                        value={"Sedentary"}
                        checked={content?.data?.excercise_profile ==="Sedentary"}
                        disabled={true}
                    />
                    <RadioButton
                        label={"Moderately Active"}
                        name={"excercise_profile"}
                        value={"Moderately Active"}
                        checked={content?.data?.excercise_profile ==="Moderately Active"}
                        disabled={true}
                    />
                    <RadioButton
                        label={"Active"}
                        name={"excercise_profile"}
                        value={"Active"}
                        checked={content?.data?.excercise_profile ==="Active"}
                        disabled={true}
                    />
                    <RadioButton
                        label={"Very Active"}
                        name={"excercise_profile"}
                        value={"Very Active"}
                        checked={content?.data?.excercise_profile ==="Very Active"}
                        disabled={true}
                    />
                </div>
            </div>

            <div className='border rounded my-4 overflow-hidden'>
                <div className="bg-light text-center text-brown text-uppercase border-bottom py-2 fw-semibold">
                Measurements
                </div>
                <div className="row m-0 fw-semibold" >
                    <div className='p-2 border col-3'></div>
                    <div className='p-2 border col-9'>(inches)</div>
                </div>
                    {
                        FitnessArrivalConsultationField?.measurement?.map((item, index)=>{
                            return (
                                <div className="row m-0" key={index}>
                                    <div className=" border border-bottom-0 p-2 col-3 fw-semi-bold text-secondary ">{item.label}</div>
                                    <div className='border border-bottom-0 p-2 col-9'>
                                        {content?.data?.measurements?.[item.key] || '-'}
                                    </div>
                                </div>
                            )
                        })
                    }
            </div>

            <div className='border rounded my-3 overflow-hidden'>
                <div className="bg-light text-center py-2 fw-semibold  text-brown border-bottom">
                SUMMARY ASSESSMENT AND TREATMENT PLAN
                </div>
                <div className='m-3'>
                    <div>
                    <div className='fw-semibold mb-3 text-uppercase  text-secondary'>Primary objectives for improvement: <span className="fw-normal text-dark">{content?.data?.summary_assessment_treatment_plan?.objectives?.length ? '' : <>&nbsp; -</>} </span> </div>
                    {
                            content?.data?.summary_assessment_treatment_plan?.objectives?.map((item, index)=>{
                                return (
                                    <div className='border mb-4' key={index}>
                                        <div className='fw-semibold text-secondary p-2 bg-light text-uppercase'> 
                                            <img src={targetIcon} className='me-2' width={'20px'} alt="" />{(index+1)?.toString()?.padStart(2, 0)}

                                        </div>
                                        <table className='p-2 w-100'>
                                        <tbody>
                                            {
                                                FitnessArrivalConsultationField?.objectives?.map((item, subIndex)=>(
                                                    <tr className='d-flex' key={subIndex}>
                                                        <td className="fw-semi-bold border p-2 mr-2 flex-shrink-0" style={{width: '230px'}}>{item?.label}</td>
                                                        <td className="text-break border flex-grow-1 p-2 white-space">{content.data?.summary_assessment_treatment_plan?.objectives?.[index]?.[item.key] || '-'}</td>
                                                    </tr>
                                                    ))
                                            }
                                        </tbody>
                                    </table>
                                    </div>
                                )
                            })
                        }
                        <div className='ms-3'>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
