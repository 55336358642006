import TextArea from '../../../../../components/TextArea/TextArea'
import { TextField } from '../../../../../components/TextField/TextField'
import './AddEditFitnessArrivalConsultation.scss'
import {ADD, FitnessArrivalConsultationField, FitnessArrivalConsultationJSON } from '../../NoteFormsConstants'
import DatePicker from '../../../../../components/DatePicker/DatePicker'
import moment from 'moment'
import {ReactComponent as RemoveBtn } from '../../../../../assets/images/delete.svg'
import RadioButton from '../../../../../components/RadioButton/RadioButton'
import targetIcon from '../../../../../assets/images/target.svg'
import { useEffect } from 'react'

const Objectives = ({setKey, removeObjective, content, handleAddMore})=>{
    return (
        <div className="border w-100 rounded mt-5 summary">
            <div className="heading py-3">
                SUMMARY ASSESSMENT AND TREATMENT PLAN
            </div>
            <div className='p-3'>
                <div className="sub-heading py-4">
                    Primary objectives for improvement
                </div>
                <div className="d-flex flex-wrap align-items-stretch justify-content-start">
                    {
                        content?.data?.summary_assessment_treatment_plan?.objectives?.map((subitem, subindex)=> {
                            return (
                                <div className="objective-box p-2" key={subindex}>
                                    <div className='border rounded'>
                                        <div className="heading p-3 py-2 d-flex justify-content-between align-items-center">
                                        <div>
                                    <img src={targetIcon} className='me-2' width={'20px'} alt="" />{(subindex+1)?.toString()?.padStart(2, 0)}
                                    </div>                                            <div className='remove-btn' onClick={()=>removeObjective(subindex)}>
                                                <RemoveBtn/>
                                            </div>
                                        </div>
                                        <div className="p-3">
                                        {
                                            FitnessArrivalConsultationField?.objectives?.map((item, index)=>{
                                                return (
                                                    <div className="data-row d-flex" key={index}>
                                                        <div className="fw-semi-bold mt-2" style={{width: '210px'}}>{item.label}</div>
                                                        <div className='data-input'>
                                                            <TextArea
                                                                placeholder={"Enter "}
                                                                onChange={value =>setKey(["summary_assessment_treatment_plan", "objectives", subindex, item.key], value)}
                                                                row={2}
                                                                value={content?.data?.summary_assessment_treatment_plan?.objectives?.[subindex]?.[item.key] || ''}/>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    
                    
                    <div className="p-2 w-50 "  onClick={handleAddMore}>
                        <div className="add-more ">
                            Add More Objectives
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export const AddEditFitnessArrivalConsultation = ({content, setContent, conductedBy, type,  guestId, userProgramId, formType, uploadComponent})=>{
  const setKey = (keys, value)=>{
      setContent(prev=>{
        let updatedData = { ...prev}
        keys.reduce((acc, key, index) => {
            if (index === keys.length - 1) {
              acc[key] = value; 
            } else {
                if(Array.isArray(acc[key])){
                    acc[key] = [...acc[key] ]
                }else{
                    acc[key] = { ...acc[key] };
                }
            }
            return acc[key];
          }, updatedData.data);
        return updatedData;
      })
    }

    const removeObjective = (index)=>{
        setContent(prev=>{
            let updatedData = {...prev};
            updatedData.data.summary_assessment_treatment_plan.objectives.splice(index, 1);
            return updatedData
        })
    }
    
    const handleAddMore=()=>{
        setContent(prev=>{
            let updatedData = { ...prev}
            updatedData.data.summary_assessment_treatment_plan.objectives = [...updatedData.data.summary_assessment_treatment_plan.objectives, FitnessArrivalConsultationJSON.data.summary_assessment_treatment_plan.objectives[0]]
            return updatedData;
        })
    }

    useEffect(()=>{
        if(formType===ADD){
            setContent(prev=>{
                let newData = {...prev}
                newData.date = moment().format("YYYY-MM-DD")
                return newData
            })
        }
    }, [])

    return (
      <div className='fitness-arrival-consultation-form'>
          <>
          <div className="d-flex py-2 mb-3 rounded justify-content-between">
              <div className="d-flex align-items-center">
                <div className="title text-secondary me-3">Conducted By: </div>
                {/* <TextField
                    width={'300px'}
                    type={'text'}
                    placeholder='Enter name' 
                    value={content?.conducted_by || ''} 
                    onChange={e=>setContent( {...content, 'conducted_by' : e.target.value})}
                  /> */}
                  {conductedBy || '-'}
              </div>
              <div className="d-flex align-items-center">
                <div className="title text-secondary me-3">Date</div>
                <DatePicker
                  value={content.date ? new Date(content.date) : ''}
                  onChange={date=>setContent( {...content, 'date' : moment(date).format('YYYY-MM-DD')})}
                  width={'300px'}
                />
              </div>
            </div>
            <div className="border w-100 mb-5 rounded current-dietary-pattern ">
                <div className="heading py-3 text-uppercase">
                    Cardio (Treadmill) Test
                </div> 
                <div className='p-3 cardio-section'>
                    <div className="row m-0 pb-2 mb-3 border-bottom fw-semi-bold text-muted">
                        <div className="col-2 p-1"></div>
                        <div className="col-2 p-1">Speed</div>
                        <div className="col-2 p-1">Incline</div>
                        <div className="col-2 p-1">Duration</div>
                        <div className="col-2 p-1">HR</div>
                        <div className="col-2 p-1">Comments</div>
                    </div>
                    {
                        FitnessArrivalConsultationField?.cardioTest?.map((item, index)=>{
                            return (
                                <div className="row m-0 " key={index}>
                                    <div className="col-2 p-1 pt-2 fw-semi-bold">{item.label}</div>
                                    <div className="col-2 p-1 pt-2">
                                        <TextField 
                                            type={'numeric'}
                                            maxLength="5"
                                            placeholder='Enter ' 
                                            unit={'KMPH'}
                                            value={content?.data?.cardio_test?.[item.key]?.speed || ''} 
                                            onChange={e =>setKey(["cardio_test", item.key, "speed"], e.target.value)}
                                        />
                                    </div>
                                    <div className="col-2 p-1 pt-2">
                                        <TextField 
                                            type={'numeric'}
                                            maxLength="5"
                                            placeholder='Enter ' 
                                            unit={'%'}
                                            value={content?.data?.cardio_test?.[item.key]?.incline || ''} 
                                            onChange={e =>setKey(["cardio_test", item.key, "incline"], e.target.value)}
                                        />
                                    </div>
                                    <div className="col-2 p-1 pt-2">
                                        <TextField 
                                            type={'numeric'}
                                            maxLength="5"
                                            placeholder='Enter ' 
                                            unit={'Min'}
                                            value={content?.data?.cardio_test?.[item.key]?.duration || ''} 
                                            onChange={e =>setKey(["cardio_test", item.key, "duration"], e.target.value)}
                                        />
                                    </div>
                                    <div className="col-2 p-1">
                                        <TextArea
                                            row={3}
                                            placeholder={"Enter "}
                                            onChange={value =>setKey(["cardio_test", item.key, "hr"], value)}
                                            value={content?.data?.cardio_test?.[item.key]?.hr || ''}/>
                                    </div>
                                    <div className="col-2 p-1">
                                        <TextArea
                                            row={3}
                                            placeholder={"Enter "}
                                            onChange={value =>setKey(["cardio_test", item.key, "comments"], value)}
                                            value={content?.data?.cardio_test?.[item.key]?.comments || ''}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="border w-100 mb-5 rounded current-dietary-pattern ">
                <div className="heading py-3 text-uppercase">
                    Strength
                </div> 
                <div className='p-3'>
                    <div className="row m-0 mb-3 pb-2 border-bottom fw-semi-bold text-muted">
                        <div className="col-3 p-1"></div>
                        <div className="col-3 p-1">Weight</div>
                        <div className="col-3 p-1">Reps</div>
                        <div className="col-3 p-1">Comments</div>
                    </div>
                    {
                        FitnessArrivalConsultationField?.strength?.map((item, index)=>{
                            return (
                                <div className="row m-0 " key={index}>
                                    <div className="col-3 p-1 pt-2 fw-semi-bold">{item.label}</div>
                                    <div className="col-3 p-1 pt-2">
                                        <TextField 
                                            type={'numeric'}
                                            maxLength="5"
                                            placeholder='Enter ' 
                                            unit={'Kg'}
                                            value={content?.data?.strength?.[item.key]?.weight || ''} 
                                            onChange={e =>setKey(["strength", item.key, "weight"], e.target.value)}
                                        />
                                    </div>
                                    <div className="col-3 p-1 pt-2">
                                        <TextField 
                                            type={'numeric'}
                                            maxLength="5"
                                            placeholder='Enter ' 
                                            value={content?.data?.strength?.[item.key]?.reps || ''} 
                                            onChange={e =>setKey(["strength", item.key, "reps"], e.target.value)}
                                        />
                                    </div>
                                    <div className="col-3 p-1">
                                        <TextArea
                                            row={3}
                                            placeholder={"Enter "}
                                            onChange={value =>setKey(["strength", item.key, "comments"], value)}
                                            value={content?.data?.strength?.[item.key]?.comments || ''}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="border w-100 mb-5 rounded current-dietary-pattern ">
                <div className="heading py-3 text-uppercase">
                    Muscular Endurance
                </div> 
                <div className='p-3'>
                    <div className="row m-0 mb-3 fw-semi-bold text-muted">
                        <div className="col-3 p-1 border-bottom"></div>
                        <div className="col-4 p-1 border-bottom">Unit</div>
                        <div className="col-5 p-1 border-bottom">Comments</div>
                    </div>
                    {
                        FitnessArrivalConsultationField?.muscularEndurance?.map((item, index)=>{
                            return (
                                <div className="row m-0 " key={index}>
                                    <div className="col-3 p-1 fw-semi-bold">{item.label}</div>

                                    <div className="col-4 p-1">
                                        <TextArea
                                            row={3}
                                            placeholder={"Enter "}
                                            onChange={value =>setKey(["muscular_endurance", item.key, "units"], value)}
                                            value={content?.data?.muscular_endurance?.[item.key]?.units || ''}/>
                                    </div>
                                    <div className="col-5 p-1">
                                        <TextArea
                                            row={3}
                                            placeholder={"Enter "}
                                            onChange={value =>setKey(["muscular_endurance", item.key, "comments"], value)}
                                            value={content?.data?.muscular_endurance?.[item.key]?.comments || ''}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="border w-100 mb-5 rounded current-dietary-pattern ">
                <div className="heading py-3 text-uppercase">
                    Flexibility
                </div> 
                <div className='p-3'>
                    <div className="row m-0 mb-3 fw-semi-bold text-muted">
                        <div className="col-3 p-1 border-bottom"></div>
                        <div className="col-3 p-1 border-bottom">Right Side</div>
                        <div className="col-3 p-1 border-bottom">Left Side</div>
                        <div className="col-3 p-1 border-bottom">Comments</div>
                    </div>
                    {
                        FitnessArrivalConsultationField?.flexibility?.map((item, index)=>{
                            return (
                                <div className="row m-0 " key={index}>
                                    <div className="col-3 p-1 fw-semi-bold">{item.label}</div>
                                    <div className="col-3 p-1">
                                        <TextArea
                                            row={3}
                                            placeholder={"Enter "}
                                            onChange={value =>setKey(["flexibility", item.key, "right_side"], value)}
                                            value={content?.data?.flexibility?.[item.key]?.right_side || ''}/>
                                    </div>
                                    <div className="col-3 p-1">
                                        <TextArea
                                            row={3}
                                            placeholder={"Enter "}
                                            onChange={value =>setKey(["flexibility", item.key, "left_side"], value)}
                                            value={content?.data?.flexibility?.[item.key]?.left_side || ''}/>
                                    </div>
                                    <div className="col-3 p-1">
                                        <TextArea
                                            row={3}
                                            placeholder={"Enter "}
                                            onChange={value =>setKey(["flexibility", item.key, "comments"], value)}
                                            value={content?.data?.flexibility?.[item.key]?.comments || ''}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="border w-100 mb-5 rounded current-dietary-pattern ">
                <div className="fw-semi-bold p-3 text-uppercase">
                    Exercise Profile
                </div> 
                <div className='p-3 pt-0'>
                    <RadioButton
                        name={"excercise_profile"}
                        value={"Sedentary"}
                        label={"Sedentary"}
                        checked={content?.data?.excercise_profile==="Sedentary"}
                        onChange={(e)=>{setKey(["excercise_profile"], e.target.value)}}
                    />
                    <RadioButton
                        name={"excercise_profile"}
                        value={"Moderately Active"}
                        label={"Moderately Active"}
                        checked={content?.data?.excercise_profile==="Moderately Active"}
                        onChange={(e)=>{setKey(["excercise_profile"], e.target.value)}}
                    />
                    <RadioButton
                        name={"excercise_profile"}
                        value={"Active"}
                        label={"Active"}
                        checked={content?.data?.excercise_profile==="Active"}
                        onChange={(e)=>{setKey(["excercise_profile"], e.target.value)}}
                    />
                    <RadioButton
                        name={"excercise_profile"}
                        value={"Very Active"}
                        label={"Very Active"}
                        checked={content?.data?.excercise_profile==="Very Active"}
                        onChange={(e)=>{setKey(["excercise_profile"], e.target.value)}}
                    />
                    
                </div>
            </div>
            <div className="border w-100 mb-5 rounded current-dietary-pattern ">
                <div className="heading py-3 text-uppercase">
                    Measurements
                </div> 
                <div className='p-3'>
                    <div className="row m-0 mb-3 fw-semi-bold text-muted">
                        <div className="col-3 p-1 border-bottom"></div>
                        <div className="col-9 p-1 border-bottom">(inches)</div>
                    </div>
                    {
                        FitnessArrivalConsultationField?.measurement?.map((item, index)=>{
                            return (
                                <div className="row m-0 " key={index}>
                                    <div className="col-3 p-1 pt-2 fw-semi-bold">{item.label}</div>
                                    <div className="col-6 pb-2 p-1">
                                            <TextField 
                                                type={'numeric'}
                                                maxLength="5"
                                                placeholder='Enter value' 
                                                value={content?.data?.measurements?.[item.key] || ''} 
                                                onChange={e =>setKey(["measurements", item.key], e.target.value)}
                                            />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <Objectives
                content={content}
                removeObjective={removeObjective}
                setKey={setKey}
                handleAddMore={handleAddMore}
            />
          </>
          <div className="w-50">
          {uploadComponent}
          </div>
      </div>
    )
}